import React, { useState, useEffect, useRef } from 'react'
import Seo from 'components/seo'
import Layout from 'layouts/zh'
import Button, { ButtonGroup } from 'components/Base/Button'
import SolutionsBanner from 'components/SolutionsBanner'
import SectionContent, { SectionHeaderMobile } from 'components/SectionContent'
import PictureSwiper from 'components/PictureSwiper'
import SolutionsSwiper from 'components/SolutionsSwiper'
import SolutionsSwiperMobile from 'components/SolutionsSwiperMobile'
import MaterialCard, { MaterialCardGroup, MaterialCardTypes } from 'components/MaterialCard'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import PageBottomCard from 'components/PageBottomCard'
import Video from 'components/Base/Video'
import SwiperCore, { Pagination, Scrollbar, A11y, Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { isPc } from 'utils/utils'

import 'swiper/swiper.min.css'
import * as styles from './index.module.less'

import IconArrow from 'assets/images/solution/cdp/icon-arrow.svg'

SwiperCore.use([Pagination, Scrollbar, A11y, Autoplay, Navigation])

const CDP: React.FC = (props) => {
  return (
    <Layout {...props}>
      <CDPState />
    </Layout>
  )
}

const CDPState: React.FC = () => {
  const [inPC, setInPC] = useState<boolean>(isPc())
  const [currentPlay, setCurrentPlay] = useState<number>()

  useEffect(() => {
    window.onresize = () => {
      setInPC(isPc())
    }
  }, [])

  // 更多关于营销的实战资料
  const MaterialList = [
    {
      imgSrc: require(`assets/images/solution/cdp/material_01.png`).default,
      title: '用户画像从方法到实践',
      desc: '得用户者得天下，懂用户者得未来；拆解电商平台标签体系构建',
      href: 'https://www.sensorsdata.cn/market/playback-detail.html?id=3413',
      buttonText: '免费观看课程',
      type: MaterialCardTypes.Video,
    },
    {
      imgSrc: require(`assets/images/solution/cdp/material_02.png`).default,
      title: '标签体系从创新到突破',
      desc: '从业务应用的流程进行反推，构建可落地的标签画像体系',
      href: 'https://www.sensorsdata.cn/market/playback-detail.html?id=3390',
      buttonText: '免费下载文档',
      type: MaterialCardTypes.Document,
    },
    {
      imgSrc: require(`assets/images/solution/cdp/material_03.png`).default,
      title: '行业可落地的 CDP 实践',
      desc: '搭建数据互通的 CDP 平台，构建 360°车主用户画像完成数据闭环',
      href: 'https://www.sensorsdata.cn/school/library/d210fe00a27a9f5ad8e504f1f9a8a193',
      buttonText: '免费下载白皮书',
      type: MaterialCardTypes.Whitepaper,
    },
    {
      imgSrc: require(`assets/images/solution/cdp/material_04.png`).default,
      title: '搭建高质量 CDP',
      desc: '搭建高质量用户数据平台（CDP），驰骋数字化转型新战场',
      href: 'https://www.sensorsdata.cn/market/playback-detail.html?id=3835',
      buttonText: '免费观看课程',
      type: MaterialCardTypes.Video,
    },
  ]
  // 这些企业正在用神策营销自动化创造价值
  const CustomerVideos = [
    {
      title: '环球网校：搭建用户画像，洞察教育赛道新生机',
      poster: require(`assets/images/solution/cdp/poster_01.png`).default,
      isPlaying: false,
      videoUrl:
        'https://video.sensorsdata.cn/a7c686355670452bb59e6660662c259b/7d289f9c3f9e43e399be24c152583278-de7008d20d06f60c4d9e8d7db7c1dd29-ld.mp4',
    },
    {
      title: '某科技公司：标签体系助力用户全生命周期运营',
      poster: require(`assets/images/solution/cdp/poster_02.png`).default,
      isPlaying: false,
      videoUrl:
        'https://video.sensorsdata.cn/253867ac45414b72af1964db0abcfaa0/71b4076f828c4b5bbf19f3041648ecbd-ebeb6b67625cdb5885056e6b7ef2555a-sd.mp4',
    },
  ]

  return (
    <>
      <Seo
        title="CDP客户数据平台｜自动化营销平台-神策数据"
        description="神策CDP客户数据平台在数据采集、打通、整合全渠道营销触点基础上，构建完整的用户标签体系，还原真实的用户画像，帮助企业搭建营销数据中台，提升数字化营销能力，实现客户精细化运营。"
        keywords="CDP,客户数据平台,数据中台,数字化营销,用户画像,营销自动化"
        saTitle="首页｜产品 - CDP 解决方案"
      />
      <main className={styles.CDPContainer}>
        <SolutionsBanner
          className="banner-wrap"
          title="CDP 解决方案"
          subTitle="治理全域数据，打造 One ID，构建标签体系，输出数据赋能业务"
          desc="CDP（客户数据平台）在采集、打通、治理全域数据的基础上，整合用户多平台 ID 并实现唯一识别，进而构建完整的用户标签体系，向其他业务应用输出数据能力。"
          imgUrl={<img src={require(`assets/images/solution/cdp/banner.png`).default} alt="" className="banner-icon" />}
          buttonGroup={
            <Button href="/doMatrixSps?formpage_source2=CDP" target="_blank">
              体验 CDP 解决方案 Demo
            </Button>
          }
          mobileHideButton
          mobileHideDesc
          mobileHideIcon
        />
        <SectionContent className="section-02">
          <SectionHeaderMobile title="前沿洞察" />
          <a className="left" href="https://www.sensorsdata.cn/market/playback-detail.html?id=4123" target="_blank">
            <img src={require(`assets/images/solution/cdp/kotler.gif`).default} alt="" />
            <span key={Math.random()} className="left-float">
              <h3 key={Math.random()} className="left-float-title">
                当从业者每日高呼 CDP 时，究竟在谈什么？
              </h3>
            </span>
          </a>
          <div
            key={Math.random()}
            className="right section-02-right"
            onClick={() => {
              if (!inPC) window.location.href = 'https://sdmarketing.wjx.cn/vj/h7J0C2V.aspx'
            }}
          >
            <h3 key={Math.random()} className="right-title">
              当从业者每日高呼 CDP 时，究竟在谈什么？
            </h3>
            <ul key={Math.random()} className="right-list">
              <li>CDP 起源与概念</li>
              <li>CDP 能力差异点</li>
              <li>CDP 应用场景</li>
            </ul>
            <div key={Math.random()} className="sd-button-group right-button-group">
              <Button
                href="https://www.sensorsdata.cn/market/playback-detail.html?id=4123"
                btnType="primary"
                target="_blank"
              >
                观看完整解读
              </Button>
            </div>
          </div>
        </SectionContent>
        <SectionContent
          className="section-03"
          title="从行业的前沿实战介绍，掌握 CDP 的应用场景"
          backgroundColor="#F5FBFF"
        >
          <SectionHeaderMobile
            title="实战资料"
            right={
              <Button href="//www.sensorsdata.cn/school/library" btnType="link" size="small">
                更多资料 <img className="icon-arrow" src={IconArrow} alt="" />
              </Button>
            }
          />
          <div className="section-03--content">
            <div
              className="left"
              onClick={() => {
                if (!inPC)
                  window.location.href = 'https://www.sensorsdata.cn/school/library/6737a3cc676e82572e76d765e5513d4b'
              }}
            >
              <p className="desc-mb hidden-pc">代表性品牌零售企业关于 CDP 的实践探索与创新成果</p>
              <img src={require(`assets/images/solution/cdp/section_03_thumb.png`).default} alt="" />
            </div>
            <div className="right hidden-mb">
              <h3>《品牌零售业数字化运营的方法论及实践》</h3>
              <p>代表性品牌零售企业关于 CDP 的实践探索与创新成果</p>
              <ul>
                <li>类型：白皮书</li>
                <li>页数：26 页</li>
                <li>关键词：数字化运营，趋势，挑战，破局，实战</li>
              </ul>
              <PictureSwiper
                className="picture-swiper-wrap"
                dataSource={[
                  require(`assets/images/solution/cdp/report-content-01.png`).default,
                  require(`assets/images/solution/cdp/report-content-02.png`).default,
                  require(`assets/images/solution/cdp/report-content-03.png`).default,
                  require(`assets/images/solution/cdp/report-content-04.png`).default,
                ]}
              />
              <div className="sd-button-group">
                <Button
                  btnType="primary"
                  href="https://www.sensorsdata.cn/school/library/6737a3cc676e82572e76d765e5513d4b"
                  target="_blank"
                >
                  免费下载
                </Button>
              </div>
            </div>
          </div>
          <MaterialCardGroup>
            {MaterialList?.map((item) => (
              <MaterialCard key={item.title} {...item} />
            ))}
          </MaterialCardGroup>
        </SectionContent>
        <SectionContent className="section-04" title="CDP 的四大核心能力">
          <SectionHeaderMobile title="CDP 的四大核心能力" />
          {/* pc端 */}
          <SolutionsSwiper
            className="solutions-swiper-wrap"
            dataSource={[
              {
                className: 'feature-01',
                icon: 'universe',
                tabText: (
                  <>
                    全域数据
                    <br className="hidden-pc" />
                    采集与治理
                  </>
                ),
                title: '全域数据采集与治理',
                imgUrl: require('assets/images/solution/cdp/feature_01.png').default,
                imgWidth: 630,
                descList: [
                  {
                    title: '采集官网、App、小程序等自有平台数据',
                  },
                  {
                    title: '接入线上渠道、广告媒介、平台生态等三方数据',
                  },
                  {
                    title: '打通 CRM、DMS、呼叫中心等业务系统数据',
                  },
                  {
                    title: '安全的平台化 ETL 数据清洗及沉淀',
                  },
                ],
                buttonList: [
                  {
                    text: '体验 CDP 解决方案 Demo',
                    href: '/doMatrixSps?formpage_source2=CDP',
                    target: '_blank',
                    type: 'primary',
                  },
                ],
              },
              {
                className: 'feature-02',
                icon: 'identify',
                tabText: (
                  <>
                    One ID <br className="hidden-pc" />
                    唯一标识打造
                  </>
                ),
                title: '一个标识唯一标识打造',
                imgUrl: require('assets/images/solution/cdp/feature_02.png').default,
                imgWidth: 630,
                descList: [
                  {
                    title: '多端多系统用户 ID 实时动态关联',
                  },
                  {
                    title: '深度全域的先进 ID-Mapping 技术方案',
                  },
                  {
                    title: '标准的接入-验证-关联-存储流程',
                  },
                  {
                    title: '循环重映射自动多边形 ID 范围',
                  },
                ],
                buttonList: [
                  {
                    text: '体验 CDP 解决方案 Demo',
                    href: '/doMatrixSps?formpage_source2=CDP',
                    target: '_blank',
                    type: 'primary',
                  },
                ],
              },
              {
                className: 'feature-03',
                icon: 'portrayal',
                tabText: (
                  <>
                    客户标签
                    <br className="hidden-pc" />
                    画像体系构建
                  </>
                ),
                title: '客户标签画像架构',
                imgUrl: require('assets/images/solution/cdp/feature_03.png').default,
                imgWidth: 630,
                descList: [
                  {
                    title: '基于全习惯用户行为的多维度用户标签',
                  },
                  {
                    title: '适配 30+ 行业需求的场景化标签体系',
                  },
                  {
                    title: '360° 单用户全景画像与用户群画像',
                  },
                  {
                    title: '高并发的实时标签在线调取能力',
                  },
                ],
                buttonList: [
                  {
                    text: '体验 CDP 解决方案 Demo',
                    href: '/doMatrixSps?formpage_source2=CDP',
                    target: '_blank',
                    type: 'primary',
                  },
                ],
              },
              {
                className: 'feature-04',
                icon: 'output',
                tabText: (
                  <>
                    数据能力
                    <br className="hidden-pc" />
                    多平台输出
                  </>
                ),
                title: '数据能力多平台输出',
                imgUrl: require('assets/images/solution/cdp/feature_04.png').default,
                imgWidth: 630,
                descList: [
                  {
                    title: '向多个平台输出高可用数据',
                  },
                  {
                    title: '赋能营销自动化目标人群圈选',
                  },
                  {
                    title: '提供 DMP 广告受众搜索包匹配',
                  },
                  {
                    title: '支持机器人推荐系统实现内容推荐',
                  },
                ],
                buttonList: [
                  {
                    text: '体验 CDP 解决方案 Demo',
                    href: '/doMatrixSps?formpage_source2=CDP',
                    target: '_blank',
                    type: 'primary',
                  },
                ],
              },
            ]}
          />
          {/* 移动端 */}
          <SolutionsSwiperMobile
            dataSource={[
              {
                title: '全域数据采集与治理',
                imgUrl: require('assets/images/solution/cdp/feature_01.png').default,
              },
              {
                title: '一个标识唯一标识打造',
                imgUrl: require('assets/images/solution/cdp/feature_02.png').default,
              },
              {
                title: '客户标签画像架构',
                imgUrl: require('assets/images/solution/cdp/feature_03.png').default,
              },
              {
                title: '数据能力多平台输出',
                imgUrl: require('assets/images/solution/cdp/feature_04.png').default,
              },
            ]}
          />
          {/* 移动端按钮 */}
          <ButtonGroup className="hidden-pc">
            <Button btnType="primary" href="/doMatrixSps?formpage_source2=CDP" target="_blank">
              体验 CDP 解决方案 Demo
            </Button>
          </ButtonGroup>
        </SectionContent>
        {/* pc端显示，移动端隐藏 */}
        <SectionContent className="section-05" title="更多关于 CDP 的实战资料" backgroundColor="#F5FBFF">
          <MaterialCardGroup>
            {MaterialList?.map((item) => (
              <MaterialCard key={item.title} {...item} />
            ))}
          </MaterialCardGroup>
        </SectionContent>
        <SectionContent className="section-06" title="这些企业正在用神策 CDP 创造价值">
          <SectionHeaderMobile title="这些企业正在用神策 CDP 创造价值" />
          {/* PC端 */}
          <div className="video-list hidden-mb">
            {CustomerVideos?.map((item, index) => (
              <div className="video-item" key={item.title}>
                <Video
                  source={[
                    {
                      src: item.videoUrl,
                      type: 'video/mp4',
                    },
                  ]}
                  playStatus={currentPlay === index}
                  poster={item.poster}
                  onPlayCallback={() => {
                    setCurrentPlay(index)
                    item.isPlaying = true
                  }}
                />
                {!item.isPlaying && <h3 className="video-title">{item.title}</h3>}
              </div>
            ))}
          </div>
          {/* 移动端 */}
          <Swiper className="video-list-swiper hidden-pc" pagination={{ clickable: true }} autoplay={false}>
            {CustomerVideos?.map((item, index) => (
              <SwiperSlide key={item.title}>
                <div className="video-item">
                  <Video
                    source={[
                      {
                        src: item.videoUrl,
                        type: 'video/mp4',
                      },
                    ]}
                    playStatus={currentPlay === index}
                    poster={item.poster}
                    onPlayCallback={() => {
                      setCurrentPlay(index)
                      item.isPlaying = true
                    }}
                  />
                  {!item.isPlaying && <h3 className="video-title">{item.title}</h3>}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="sd-button-group hidden-mb">
            <ScanCodeConsultButton
              buttonText="扫码咨询 CDP 专家"
              qrcode={require(`assets/images/solution/cdp/qrcode1.png`).default}
            />
          </div>
          <div className="logo-wall lg:!pl-[0]">
            <img
              className="hidden-mb lg:mx-auto"
              src={require(`assets/images/solution/cdp/customer_logo.png`).default}
              alt=""
            />
            <img
              className="hidden-pc"
              src={require(`assets/images/solution/cdp/customer_logo_mb.png`).default}
              alt=""
            />
          </div>
        </SectionContent>
        <PageBottomCard
          title="与神策业务专家共同探讨 CDP 建设的更优路径！"
          desc="立即预约，快速找到适合您的 CDP 解决方案！"
          leftButtonText="体验 CDP 解决方案 Demo"
          leftButtonHref="/doMatrixSps?formpage_source2=CDP"
          rightButtonText="预约演示"
          rightButtonHref="/form/parade.html?formpage_source2=CDP预约演示"
        />
      </main>
    </>
  )
}

export default CDP
